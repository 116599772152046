import React, { useState } from "react"
import { FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl"
import { Alert } from "react-bootstrap"
import axios from "axios"
import "./Contact.scss"

const Contact = () => {
    const [userEmail, setUserEmail] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [userMessage, setUserMessage] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [checkGdpr, setCheckGdpr] = useState(false);

    const [formSubmitted, setFormSubmitted] = useState(false);

    const [successAlert, setSuccessAlert] = React.useState({
        text: 'contact.alert.noData',
        show: false,
    });
    const [warningAlert, setWarningAlert] = React.useState({
        text: 'contact.alert.messageSent',
        show: false,
    });

    const sendMail = () => {
        if (!emailSent) {
            setFormSubmitted(true);
            const mailRegex = new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$");
            var phoneRegex = new RegExp("^[+]?[()/0-9]{9,}$");
            var canSend = true;

            if (!userPhone && !userEmail && !userMessage) {

                setWarningAlert({ text: "contact.alert.noData", show: true });
                canSend = false;
            } else {
                if (!checkGdpr) {
                    canSend = false;
                    setWarningAlert({ text: "contact.alert.gdpr", show: true });
                    return;
                }
                if (!mailRegex.test(userEmail)) {
                    canSend = false;
                    setWarningAlert({ text: "contact.alert.badEmail", show: true });
                    return;
                }
                if (!phoneRegex.test(userPhone)) {
                    canSend = false;
                    setWarningAlert({ text: "contact.alert.badNumber", show: true });
                    return;
                }
                if (userMessage.length < 10) {
                    canSend = false;
                    setWarningAlert({ text: "contact.alert.shortMessage", show: true });
                    return;
                }
            }

            if (canSend) {
                var message = "<b>User Email:</b> " + userEmail + "<br><b>User Phone:</b> " + userPhone + "<br><b>Message from user:</b> " + userMessage;
                axios({
                    method: "post",
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": "Basic Y29hbGlvczpNYWtpbmExMDE="
                    },
                    url: "/api/api/auth/sendMail",
                    data: {
                        message: message
                    }
                }).then(result => {
                    if (result.data.sent) {
                        setSuccessAlert({ text: "contact.alert.messageSent", show: true });
                        setWarningAlert({ text: "contact.alert.emailNotSent", show: false });
                        setEmailSent(true);

                        window.dataLayer.push({
                            "event": "leadSend"
                        });
                    } else {
                        setWarningAlert({ text: "contact.alert.emailNotSent", show: true });
                        setSuccessAlert({ text: "contact.alert.messageSent", show: false });
                    }
                }).catch(error => setWarningAlert({ text: "contact.alert.emailNotSent", show: false }));
            }
        }
    };

    return (
        <section id="contacts" className="page-section background-gray">
            <div className="container">
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                <FormattedMessage id="contact.title" />
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center title-description">
                    <div className="col-12 pb-5 col-lg-8 text-center">
                        <p className="title-description">
                            <FormattedHTMLMessage className="text-regular" id="contact.text" />
                        </p>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="info-box contact-label">
                        <p className="user-contact"><i className="fa fa-phone-square fa-2x contact-icon" aria-hidden="true"></i><a style={{ color: "#FF9E1B" }} href="tel:+420 602 326 793">+420 602 326 793</a></p>
                        <a href="mailto:info@coalios.cz" className="user-email"><i className="fa fa-envelope-square fa-2x contact-icon" aria-hidden="true"></i>info@coalios.cz</a>
                        <div className="contact-icons">
                            <a
                                className="social-icon"
                                href="https://www.facebook.com/coalfamily"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="fab fa-facebook-square"></i>
                            </a>
                            <a
                                className="social-icon"
                                href="https://www.instagram.com/coalfamily_/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="fab fa-instagram"></i>
                            </a>
                            <a
                                className="social-icon"
                                href="https://www.linkedin.com/company/coalios/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="fab fa-linkedin"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center" style={{ display: emailSent === true ? "none" : "" }}>
                    <div className="contact-box contact-label">
                        <FormattedHTMLMessage id="contact.field1" />
                        <input onChange={event => setUserEmail(event.target.value)} placeholder="" type="text" className={`input-field ${!userEmail && formSubmitted ? 'input-error' : ''}`} />
                        <FormattedHTMLMessage id="contact.field2" />
                        <input onChange={event => setUserPhone(event.target.value)} placeholder="" type="number" className={`input-field ${!userPhone && formSubmitted ? 'input-error' : ''}`} />
                        <FormattedHTMLMessage id="contact.field3" />
                        <textarea onChange={event => setUserMessage(event.target.value)} type="text" className={`textarea-field ${!userMessage && formSubmitted ? 'input-error' : ''}`}></textarea>
                        <div className="d-flex flex-row gdpr-panel">
                            <input type="checkbox" onChange={(e) => setCheckGdpr(e.currentTarget.checked)} className={`gdpr-checkbox ${!checkGdpr && formSubmitted ? 'input-error' : ''}`} />
                            <FormattedHTMLMessage id="contact.gdpr" />
                        </div>
                        <button type="button" className="btn" onClick={sendMail}>
                            <FormattedHTMLMessage id="contact.send" />
                        </button>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <Alert className="custom-alert" variant="success" style={{ display: successAlert.show === true ? "" : "none" }}>
                        <FormattedMessage id={successAlert.text} />
                    </Alert>
                    <Alert className="custom-alert" variant="warning" style={{ display: warningAlert.show === true ? "" : "none" }}>
                        <FormattedMessage id={warningAlert.text} />
                        <button type="button" className="closeBtn" onClick={() => setWarningAlert({ text: "contact.alert.noData", show: false })}>
                            <i className="fas fa-times"></i>
                        </button>
                    </Alert>
                </div>
            </div>
        </section>
    )
}

export default Contact