import React, { useState } from "react"
import { FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"
import Slider from "react-slick"
import noImage from "../../images/no-image.png"
import Button from "../ContactButton/contactButton"
import "./Reference.scss"
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Reference = () => {
    const sourceImages = [
        // e-commerce
        {
            img: 'ADK.png',
            customer: "ecommerce",
            // add target property for prolink - target: "https://www.google.com"
        },
        {
            img: 'am-beuaty-small.png',
            customer: "ecommerce",
        },
        {
            img: 'asklepion.png',
            customer: "ecommerce",
        },
        {
            img: 'BYECOLD.png',
            customer: "ecommerce",
        },
        {
            img: 'CAPPA.png',
            customer: "ecommerce",
        },
        {
            img: 'CLOCK.png',
            customer: "ecommerce",
        },
        {
            img: 'd-klima.png',
            customer: "ecommerce",
        },
        {
            img: 'global-nutrition.png',
            customer: "ecommerce",
        },
        {
            img: 'GS.png',
            customer: "ecommerce",
        },
        // {
        //     img: 'HANZEL_SPOL.png',
        //     customer: "ecommerce",
        // },
        {
            img: 'riteli.png',
            customer: "ecommerce",
            target: "https://www.riteli.cz/",
        },
        {
            img: 'INAMED.png',
            customer: "ecommerce",
        },
        {
            img: 'KUNSTHALLE_PRAHA.png',
            customer: "ecommerce",
        },
        {
            img: 'MAREXTRADE.png',
            customer: "ecommerce",
        },
        {
            img: 'MATPRO.png',
            customer: "ecommerce",
        },
        {
            img: 'SUDLICKA.png',
            customer: "ecommerce",
        },
        {
            img: 'SURTEP.png',
            customer: "ecommerce",
        },
        //Helios
        {
            img: 'ARANYS.png',
            customer: "helios",
        },
        {
            img: 'AUTOCENTRUM.png',
            customer: "helios",
        },
        {
            img: 'AXANT.png',
            customer: "helios",
        },
        {
            img: 'CHARITA.png',
            customer: "helios",
        },
        {
            img: 'classNekor.png',
            customer: "helios",
        },
        {
            img: 'CYKLOSVEC.png',
            customer: "helios",
        },
        {
            img: 'DAKLS.png',
            customer: "helios",
        },
        {
            img: 'DOMPRIM.png',
            customer: "helios",
        },
        {
            img: 'KARELMYL.png',
            customer: "helios",
        },
        {
            img: 'MJ.png',
            customer: "helios",
        },
        {
            img: 'MONTELA.png',
            customer: "helios",
        },
        {
            img: 'RC.png',
            customer: "helios",
        },
        {
            img: 'SEDRA.png',
            customer: "helios",
        },
        {
            img: 'SIGIFUN.png',
            customer: "helios",
        },
        {
            img: 'WECARE.png',
            customer: "helios",
        },

    ];
    const posts = useStaticQuery(graphql`
    query {
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
            nodes {
                excerpt
                fields {
                    slug
                }
                frontmatter {
                    date(formatString: "MMMM DD, YYYY")
                    name
                    position
                    reference
                    thumbnail
                }
            }
        }
    }`);
    const references = posts.allMarkdownRemark.nodes.filter(entity => entity.fields.slug?.includes("/reference-") ? entity : null);
    const isInfinite = references.length > 1 ? true : false;
    const settings = {
        dots: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: isInfinite,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
        ],
    }

    const settingsSlider = {
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1399,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    autoplaySpeed: 3000,
                },
            },
            {
                breakpoint: 512,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <section id="reference" className="page-section background-gray">
            <div className="container">
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                <FormattedMessage id="reference.title" />
                            </h2>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "-30px", marginBottom: "55px" }} className="row justify-content-center">
                    <div style={{ marginBottom: "30px" }} className="col-12  col-lg-8 text-center">
                        {/* TODO: In case english translation change to formattedHTMLText - for some reason, cannot be done now, dont have time to deal with this sht right now */}
                        <span className="customers-heading helios-customer">Spokojení <a href="#inuvio"><u>HELIOS</u></a> zákazníci</span>
                    </div>
                    <Slider className="coal-slick" {...settingsSlider} >
                        {
                            sourceImages
                                .filter(img => img.customer === "helios")
                                .map((image, index) => {
                                    if (image.target) return (
                                        <div className="slider-content" >
                                            <div>
                                                <a href={image.target} target="_blank" rel="noreferrer">
                                                    <img src={`/images/customers/${image.img}`} alt={`img ${index}`} />
                                                </a>
                                            </div>
                                        </div>
                                    )

                                    return (
                                        <div className="slider-content" >
                                            <div>
                                                <img src={`/images/customers/${image.img}`} alt={`img ${index}`} />
                                            </div>
                                        </div>
                                    )
                                })
                        }
                    </Slider>
                </div>
                <div className="full-width-slider" style={{ paddingBottom: "55px" }}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div style={{ marginTop: "30px" }} className="row pb-5 justify-content-center title-description">
                                <div className="col-12  col-lg-8 text-center">
                                    {/* TODO: In case english translation change to formattedHTMLText - for some reason, cannot be done now, dont have time to deal with this sht right now */}
                                    <span className="customers-heading">Spokojení <a style={{ color: "#27251F" }} href="https://coalshop.cz/#connector" target="_blank"><u>e-commerce</u></a> zákazníci</span>
                                </div>
                            </div>
                            <Slider className="coal-slick" {...settingsSlider} >
                                {
                                    sourceImages
                                        .filter(img => img.customer === "ecommerce")
                                        .map((image, index) => {
                                            if (image.target) return (
                                                <div className="slider-content" >
                                                    <div>
                                                        <a href={image.target} target="_blank" rel="noreferrer">
                                                            <img src={`/images/customers/${image.img}`} alt={`img ${index}`} />
                                                        </a>
                                                    </div>
                                                </div>
                                            )

                                            return (
                                                <div className="slider-content" >
                                                    <div key={index}>
                                                        <img src={`/images/customers/${image.img}`} alt={`img ${index}`} />
                                                    </div>
                                                </div>

                                            )
                                        })
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "30px" }} className="row pb-5 justify-content-center title-description">
                    <div className="col-12  col-lg-8 text-center">
                        <FormattedHTMLMessage className="text-regular" id="reference.text" />
                    </div>
                </div>
                <div className="row justify-content-center">
                    {/*<Iframe url="https://coalsoft.cz/cs/#reference"
                        width="350px"
                        className="coalsoft-frame"
                        display="initial"
                        position="relative"
                        section="reference"
                        scrolling="no"></Iframe>*/}
                    <Slider className="coal-slick" {...settings}>
                        {references.map(reference => {
                            const name = reference.frontmatter.name;
                            const position = reference.frontmatter.position;
                            const body = reference.frontmatter.reference;
                            var image = reference.frontmatter.thumbnail || noImage;
                            var splitPath = image.split("static/");

                            if (splitPath.length > 1) {
                                image = splitPath[0] + splitPath[1]
                            }
                            const largeText = body.length > 500 ? "increase-height" : "";
                            return (
                                <div className={`reference-box ${largeText}`}>
                                    <div className="img-container">
                                        <div className="imgBox">
                                            <img alt="" className="rounded-circle shadow ref-img" src={image}></img>
                                        </div>
                                    </div>
                                    <div className="reference-header">
                                        <h4 className="reference-title">{name}</h4>
                                        <h5 className="reference-subtitle text-muted">{position}</h5>
                                    </div>
                                    <div className="reference-text">
                                        <p>
                                            <span className="quote-left"><i className="fas fa-quote-left calouser-icon"></i></span>
                                            {body}
                                            <span className="quote-right"><i className="fas fa-quote-right calouser-icon"></i></span>
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div>
            <Button btnText={"Kontaktujte nás"} />
        </section>
    )
}

export default Reference