import React from "react";
import { graphql, useStaticQuery } from "gatsby"
import Carousel from "react-bootstrap/Carousel"
import Img from "gatsby-image"
import "./Home.scss"

import heliosLogo from "../../images/helios-partner-inverse.png"
import shoptetLogo from "../../images/shoptet-partner-inverse.png"
import connectorLogo from "../../images/coalshop-connector-logo-long.png"
import avaLogo from "../../images/AVA-logo-white.png"

const Home = () => {
  const data = useStaticQuery(graphql`
    query {
      imgOne: file(relativePath: { eq: "people-one-badge.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgTwo: file(relativePath: { eq: "people-two.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgThree: file(relativePath: { eq: "people-three.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgFour: file(relativePath: { eq: "AVA-place-partner.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }`)

  return (
    <section id="home" className="">
      <Carousel fade={true} pause={false}>
        <Carousel.Item interval={8000} className="corouselItem">
          <Img fluid={data.imgOne.childImageSharp.fluid} loading="eager" />
          <Carousel.Caption>
            <div className="logoTitle">
              <img alt="" className="heliosLogo" src={heliosLogo} />
              <h3 className="imageTitle">coalios s.r.o. Váš partner pro systém HELIOS iNuvio</h3>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={8000} className="corouselItem">
          <Img fluid={data.imgThree.childImageSharp.fluid} loading="eager" />
          <Carousel.Caption>
            <div className="logoTitle">
              <img style={{ marginTop: "-7%" }} alt="" className="heliosLogo" src={shoptetLogo} />
              <h3 className="imageTitle">coalios s.r.o. Váš partner pro napojení Shoptetu na HELIOS iNuvio</h3>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={8000} className="corouselItem">
          <Img fluid={data.imgTwo.childImageSharp.fluid} loading="eager" />
          <Carousel.Caption>
            <div className="logoTitle">
              <img alt="" className="heliosLogo" src={connectorLogo} />
              <h3 className="imageTitle">Nejbezpečnější propojení informačního systému HELIOS s e-shopy!</h3>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={8000} className="corouselItem">
          <Img fluid={data.imgFour.childImageSharp.fluid} loading="eager" />
          <Carousel.Caption>
            <div className="logoTitle">
              <img style={{ marginBottom: "-3%" }} alt="" className="heliosLogo" src={avaLogo} color="white" />
              <h3 className="imageTitle">Váš AVAplace partner pro rozšíření systému HELIOS a další aplikace</h3>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </section>
  )
}

export default Home
