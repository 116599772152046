import React from "react"
import { FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl"
import "./About.scss"
import Button from "../ContactButton/contactButton"
import PP2023 from "../../images/PP2023.png"
import PP2024 from "../../images/PP2024.png"
import { Link } from "gatsby"

const About = () => {
    return (
        <section id="about" className="page-section">
            <div className="container">
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                <FormattedMessage id="about.title" />
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center title-description">
                    <div className="col-12 col-lg-8 text-center">
                        <p className="title-description">
                            <FormattedHTMLMessage className="" id="about.text" />
                        </p>
                    </div>
                </div>
                <div style={{ marginBottom: "30px" }} className="row justify-content-center">
                    <div className="card">
                        <div className="card-body">
                            <div className="main-body">
                                <div className="card-row">
                                    <h5 className="card-title">Shoptet doplněk HELIOS iNuvio</h5>
                                </div>
                            </div>
                            <div className="img-body">
                                <img className="card-img" src={PP2023}></img>
                                <div className="more-link">
                                    { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                    {/* <a key="link-2023" href="/helios_inuvio_2023" className="card-link"><FormattedMessage id="inuvio.more" /></a> */}
                                    <Link className="card-link" to="/helios_inuvio_2023"><FormattedMessage id="inuvio.more" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="main-body">
                                <div className="card-row">
                                    <h5 className="card-title">Shoptet doplněk HELIOS iNuvio Premium</h5>
                                </div>
                            </div>
                            <div className="img-body">
                                <img className="card-img" src={PP2024}></img>
                                <div className="more-link">
                                    { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                    {/* <a key="link-2024" href="/helios_inuvio_2024" className="card-link"><FormattedMessage id="inuvio.more" /></a> */}
                                    <Link className="card-link" to="/helios_inuvio_2024"><FormattedMessage id="inuvio.more" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="main-body">
                                <div className="card-row">
                                    <h5 className="card-title">coalshop konektor</h5>
                                </div>
                            </div>
                            <div className="img-body">
                                <img className="card-img" src={PP2024}></img>
                                <div className="more-link">
                                    { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                    {/* <a key="link-2024" href="/helios_inuvio_2024" className="card-link"><FormattedMessage id="inuvio.more" /></a> */}
                                    <Link className="card-link" to="/coalshop_connector_2024"><FormattedMessage id="inuvio.more" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Button btnText={"Kontaktujte nás"} /> */}
                <Link to="/ebook-zdarma"><button className="section-button smaller-font" type="button">Chci více informací a e-book ZDARMA!</button></Link>
                <div style={{ paddingTop: "30px" }} className="row justify-content-center">
                    <article className="skill-about">
                        <div className="skill-icon">
                            <i className="fas fa-user-graduate fa-5x"></i>
                        </div>
                        <h3 className="skill-title">
                            <FormattedMessage id="about.values.first.title" />
                        </h3>
                        <p className="basic-text">
                            <FormattedHTMLMessage id="about.values.first.text" />
                        </p>
                    </article>
                    <article className="skill-about">
                        <div className="skill-icon">
                            <i className="fas fa-id-card fa-5x"></i>
                        </div>
                        <h3 className="skill-title">
                            <FormattedMessage id="about.values.second.title" />
                        </h3>
                        <p className="basic-text">
                            <FormattedHTMLMessage id="about.values.second.text" />
                        </p>
                    </article>
                    <article className="skill-about">
                        <div className="skill-icon">
                            <i className="fas fa-handshake fa-5x"></i>
                        </div>
                        <h3 className="skill-title">
                            <FormattedMessage id="about.values.third.title" />
                        </h3>
                        <p className="basic-text">
                            <FormattedHTMLMessage id="about.values.third.text" />
                        </p>
                    </article>
                </div>
            </div>
            {/* <Button btnText={"Kontaktujte nás"} /> */}
        </section >
    )
}

export default About