import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import "./Team.scss"

const Team = () => {
  const employees = useStaticQuery(graphql`
    query {
      uhlir: file(relativePath: { eq: "people/uhlir.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cvejn: file(relativePath: { eq: "people/cvejn.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dockalova: file(relativePath: { eq: "people/dockalova.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cervinka: file(relativePath: { eq: "people/cervinka.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kalat: file(relativePath: { eq: "people/kalat.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nouser: file(relativePath: { eq: "people/nouser.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      nyvlt: file(relativePath: { eq: "people/nyvlt.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      adamova: file(relativePath: { eq: "people/adamova.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      umova: file(relativePath: { eq: "people/umova.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sedova: file(relativePath: { eq: "people/sedova.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      felgrova: file(relativePath: { eq: "people/felgrova.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }`)

  return (
    <section id="team" className="page-section">
      <div className="container">
        <div className="row justify-content-center page-section-header">
          <div className="col-16  col-lg-8 text-center">
            <div className="section-title">
              <h2 className="font-weight-bold">
                <FormattedMessage id="team.title" />
              </h2>
            </div>
          </div>
        </div>
        <div className="row pb-5 justify-content-center">
          <div className="col-16  col-lg-8 text-center title-description-center">
            <FormattedHTMLMessage id="team.text" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="card-team">
            <div className="imgBox">
              <Img fluid={employees.uhlir.childImageSharp.fluid} className="rounded-circle shadow" />
            </div>
            <div className="align-items-center user-info">
              <div className="ml-4 w-100">
                <h5 className="card-title">Michal Uhlíř</h5>
                <h6 className="card-subtitle text-muted">Jednatel společnosti</h6>
                <div className="contact-section">
                  <a href="mailto:michal@coalios.cz" className="user-contact"><i className="fa fa-envelope-square fa-2x contact-icon" aria-hidden="true"></i>michal.uhlir@coalios.cz</a>
                </div>
              </div>
            </div>
          </div>
          <div className="card-team">
            <div className="imgBox">
              <Img fluid={employees.kalat.childImageSharp.fluid} className="rounded-circle shadow" />
            </div>
            <div className="align-items-center user-info">
              <div className="ml-4 w-100">
                <h5 className="card-title">Jakub Kalát</h5>
                <h6 className="card-subtitle text-muted">Výkonný ředitel</h6>
                <div className="contact-section">
                  <a href="mailto:jakub.kalat@coalios.cz" className="user-contact"><i className="fa fa-envelope-square fa-2x contact-icon" aria-hidden="true"></i>jakub.kalat@coalios.cz</a>
                </div>
              </div>
            </div>
          </div>
          <div className="card-team">
            <div className="imgBox">
              <Img fluid={employees.cervinka.childImageSharp.fluid} className="rounded-circle shadow" />
            </div>
            <div className="align-items-center user-info">
              <div className="ml-4 w-100">
                <h5 className="card-title">Filip Červinka</h5>
                <h6 className="card-subtitle text-muted">Marketingový ředitel</h6>
                <div className="contact-section">
                  <a href="mailto:marketing@coalios.cz" className="user-contact"><i className="fa fa-envelope-square fa-2x contact-icon" aria-hidden="true"></i>marketing@coalios.cz</a>
                </div>
              </div>
            </div>
          </div>

          <div className="card-team d-flex flex-row">
            <div className="imgBox">
              <Img fluid={employees.nyvlt.childImageSharp.fluid} className="rounded-circle shadow" />
            </div>
            <div className="align-items-center user-info">
              <div className="ml-4 w-100">
                <h5 className="card-title">Tomáš Nývlt</h5>
                <h6 className="card-subtitle text-muted">Team leader <br /> implementačního týmu</h6>
                <div className="contact-section">
                  <a href="mailto:tomas.nyvlt@coalios.cz" className="user-contact"><i className="fa fa-envelope-square fa-2x contact-icon" aria-hidden="true"></i>tomas.nyvlt@coalios.cz</a>
                </div>
              </div>
            </div>
          </div>

          <div className="card-team d-flex flex-row">
            <div className="imgBox">
              <Img fluid={employees.sedova.childImageSharp.fluid} className="rounded-circle shadow" />
            </div>
            <div className="align-items-center user-info">
              <div className="ml-4 w-100">
                <h5 className="card-title">Iveta Šedová</h5>
                <h6 className="card-subtitle text-muted">Projektová Manažerka</h6>
                <div className="contact-section">
                  <a href="mailto:iveta.sedova@coalios.cz" className="user-contact"><i className="fa fa-envelope-square fa-2x contact-icon" aria-hidden="true"></i>iveta.sedova@coalios.cz</a>
                </div>
              </div>
            </div>
          </div>

          <div className="card-team d-flex flex-row">
            <div className="imgBox column">
              <Img fluid={employees.cvejn.childImageSharp.fluid} className="rounded-circle shadow" />
            </div>
            <div className="align-items-center user-info column">
              <div className="ml-4 w-100">
                <h5 className="card-title">Jakub Cvejn</h5>
                <h6 className="card-subtitle text-muted">Obchodní manažer</h6>
                <div className="contact-section">
                  <a href="mailto:jakub@coalios.cz" className="user-contact"><i className="fa fa-envelope-square fa-2x contact-icon" aria-hidden="true"></i>jakub.cvejn@coalios.cz</a>
                </div>
              </div>
            </div>
          </div>

          <div className="card-team d-flex flex-row">
            <div className="imgBox">
              <Img fluid={employees.dockalova.childImageSharp.fluid} className="rounded-circle shadow" />
            </div>
            <div className="align-items-center user-info">
              <div className="ml-4 w-100">
                <h5 className="card-title">Markéta Dočkalová</h5>
                <h6 className="card-subtitle text-muted">HELIOS a e-commerce <br /> konzultantka</h6>
                <div className="contact-section">
                  <a style={{ fontSize: "12px" }} href="mailto:marketa@coalios.cz" className="user-contact"><i className="fa fa-envelope-square fa-2x contact-icon" aria-hidden="true"></i>marketa.dockalova@coalios.cz</a>
                </div>
              </div>
            </div>
          </div>
          <div className="card-team d-flex flex-row">
            <div className="imgBox">
              <Img fluid={employees.adamova.childImageSharp.fluid} className="rounded-circle shadow" />
            </div>
            <div className="align-items-center user-info">
              <div className="ml-4 w-100">
                <h5 className="card-title">Irena Adamová</h5>
                <h6 className="card-subtitle text-muted">HELIOS konzultantka</h6>
                <div className="contact-section">
                  <a href="mailto:irena.adamova@coalios.cz" className="user-contact"><i className="fa fa-envelope-square fa-2x contact-icon" aria-hidden="true"></i>irena.adamova@coalios.cz</a>
                </div>
              </div>
            </div>
          </div>
          <div className="card-team d-flex flex-row">
            <div className="imgBox">
              <Img fluid={employees.umova.childImageSharp.fluid} className="rounded-circle shadow" />
            </div>
            <div className="align-items-center user-info">
              <div className="ml-4 w-100">
                <h5 className="card-title">Gita Umová</h5>
                <h6 className="card-subtitle text-muted">Back Office Manažerka</h6>
                <div className="contact-section">
                </div>
              </div>
            </div>
          </div>
          <div className="card-team d-flex flex-row">
            <div className="imgBox">
              <Img fluid={employees.felgrova.childImageSharp.fluid} className="rounded-circle shadow" />
            </div>
            <div className="align-items-center user-info">
              <div className="ml-4 w-100">
                <h5 className="card-title">Eliška Felgrová</h5>
                <h6 className="card-subtitle text-muted">Marketingová Specialistka</h6>
                <div className="contact-section">
                  <a href="mailto:eliska.felgrova@coalios.cz" className="user-contact"><i className="fa fa-envelope-square fa-2x contact-icon" aria-hidden="true"></i>eliska.felgrova@coalios.cz</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Team