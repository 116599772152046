import React from "react"
import { FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl"
import Slider from "react-slick"
import "./Inuvio.scss"
import Button from "../ContactButton/contactButton"

const Inuvio = () => {
    const settings = {
        dots: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
        ],
    }

    return (
        <section id="inuvio" className="page-section background-gray">
            <div className="container">
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                <FormattedMessage id="inuvio.title" />
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center title-description">
                    <div className="col-12 pb-5 col-lg-8 text-center">
                        <p className="title-description">
                            <FormattedHTMLMessage className="text-regular" id="inuvio.text" />
                        </p>
                    </div>
                </div>
                <div className="row justify-content-center points-title">
                    <div className="col-12 pb-5 col-lg-8 text-center">
                        <h3 className="">
                            <FormattedMessage id="inuvio.points.title" />
                        </h3>
                    </div>
                </div>
                <div className="row justify-content-center middle-content">
                    <div className="col-12  col-lg-8">
                        <div className="row">
                            <div className="col-lg-6">
                                <ul className="custom-list">
                                    <li className="list-comp"><FormattedMessage id="inuvio.points.one" /></li>
                                    <li><FormattedMessage id="inuvio.points.two" /></li>
                                    <li><FormattedMessage id="inuvio.points.three" /></li>
                                    <li><FormattedMessage id="inuvio.points.four" /></li>
                                </ul>
                            </div>
                            <div className="col-lg-6">
                                <ul className="custom-list">
                                    <li><FormattedMessage id="inuvio.points.five" /></li>
                                    <li><FormattedMessage id="inuvio.points.six" /></li>
                                    <li><FormattedMessage id="inuvio.points.seven" /></li>
                                    <li><FormattedMessage id="inuvio.points.eight" /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <Slider className="coal-slick slider" {...settings}>
                        <div className="card">
                            <div className="card-body">
                                <div className="main-body">
                                    <h5 className="card-title">Ekonomika</h5>
                                    <p className="card-text">Ekonomika je srdcem Vašeho informačního systému, která musí výborně a přesně šlapat. HELIOS iNuvio
                                        snadno zajistí kompletní vedení účetní agendy ve Vaší firmě. </p>
                                </div>
                                <div className="more-link">
                                    { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                    <a href="/helios_ekonomika" className="card-link"><FormattedMessage id="inuvio.more" /></a>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="main-body">
                                    <h5 className="card-title">Sklady a obchod</h5>
                                    <p className="card-text">Získejte přehled a kontrolu nad skladovým hospodářstvím, řešte jednoduše problematiku zpracování
                                        obchodního případu, včetně návaznosti na zakázky, a zároveň ušetřete čas a snižte chybovost. </p>
                                </div>
                                <div className="more-link">
                                    { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                    <a href="/helios_sklady" className="card-link"><FormattedMessage id="inuvio.more" /></a>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="main-body">
                                    <h5 className="card-title">Výroba</h5>
                                    <p className="card-text">Potřebujete efektivní nástroj pro řízení výroby ve Vaší společnosti? HELIOS iNuvio má pro Vás
                                        ideální řešení.</p>
                                </div>
                                <div className="more-link">
                                    { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                    <a href="helios_vyroba" className="card-link"><FormattedMessage id="inuvio.more" /></a>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="main-body">
                                    <h5 className="card-title">Controlling</h5>
                                    <p className="card-text">HELIOS iNuvio nabízí škálu nástrojů pro vyhodnocování dat. Tyto nástroje umožňují komplexní pohled na
                                        data  dle Vašich potřeb a zvyklostí.</p>
                                </div>
                                <div className="more-link">
                                    { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                    <a href="helios_controling" className="card-link"><FormattedMessage id="inuvio.more" /></a>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="main-body">
                                    <h5 className="card-title">Mzdy a lidské zdroje</h5>
                                    <p className="card-text">HELIOS iNuvio Vám pomůže řídit práci s lidskými zdroji od A do Z díky modulům Personalistika a Mzdy.</p>
                                </div>
                                <div className="more-link">
                                    { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                    <a href="helios_mzdy" className="card-link"><FormattedMessage id="inuvio.more" /></a>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
            <Button btnText={"Chci nastartovat podnikání"} />
        </section>
    )
}

export default Inuvio