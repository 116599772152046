import React, { useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"
import HomeSection from "../components/Sections/Home"
import About from "../components/Sections/About"
import Inuvio from "../components/Sections/Inuvio"
import Implementation from "../components/Sections/Implementation"
import Reference from "../components/Sections/Reference"
import Team from "../components/Sections/Team"
import Contact from "../components/Sections/Contact"
import Residence from "../components/Sections/Residence"
import News from "../components/Sections/News"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeliosXShoptet from "../components/Sections/HeliosXShoptet"
import Jobs from "../components/Sections/Jobs"
import Sponsorships from "../components/Sections/Sponsorships"
import GoogleAnalytics from "../components/GoogleAnalytics/GoogleAnalytics"

const IndexPage = () => {
  const intl = useIntl()
  useEffect(() => {
    const onPageLoad = () => {
      const hash = window.location.hash;
      if (hash) {
        const section = document.getElementById(hash.substring(1));
        if (section) {
          window.scrollTo({
            top: section.offsetTop - 5,
            behavior: 'smooth',
          });
        }
      }
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <Layout>
      {/* <GoogleAnalytics /> */}
      <Seo
        lang={intl.locale}
        title={intl.formatMessage({ id: "title" })}
        keywords={['helios, helios inuvio, helios erp, erp, helios orange inuvio, helios brno, erp systém, solutions, helios orange, asseco solutions, asseco']} />
      <HomeSection />
      <About />
      <Inuvio />
      <Implementation />
      <Reference />
      <HeliosXShoptet />
      <News />
      <Team />
      <Jobs />
      <Sponsorships />
      <Contact />
      <Residence />
    </Layout>
  )
}

export default IndexPage
