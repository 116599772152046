import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl"
import noImage from "../../images/no-image.png"
import Slider from "react-slick"
import { convertToCzDate } from "../utils";

import "./News.scss"

const News = () => {
    const newsQuery = useStaticQuery(graphql`
    query {
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
            nodes {
                excerpt
                fields {
                    slug
                }
                frontmatter {
                    date(formatString: "MMMM DD, YYYY")
                    title
                    description
                    thumbnail
                }
            }
        }
    }`);
    const posts = newsQuery.allMarkdownRemark.nodes.filter(entity => entity.fields.slug?.includes("/news-") ? entity : null);

    const isInfinite = posts.length > 4 ? true : false;
    const settings = {
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: isInfinite,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
        ],
    }

    /*const [cards, setCards] = useState([]);
    useEffect(() => {
        if (cards.length === posts.length) {
            console.log(cards);
        }
    },[cards]);*/

    return (
        <section id="news" className="page-section background-gray">
            <div className="container">
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                <FormattedMessage id="news.title" />
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center title-description">
                    <div className="col-12  col-lg-8 text-center">
                        <FormattedHTMLMessage className="title" id="news.subtitle" />
                    </div>
                </div>
                <div className="row justify-content-center post-section">
                    <Slider className="coal-slick" {...settings}>
                        {posts.map((post, index) => {
                            const title = post.frontmatter.title || post.fields.slug
                            var image = post.frontmatter.thumbnail || noImage
                            var splitPath = image.split("static/")
                            if (splitPath.length > 1) {
                                image = splitPath[0] + splitPath[1]
                            }
                            const date = convertToCzDate(post.frontmatter.date);
                            return (
                                <a key={index} href={post.fields.slug} className="card-holder">
                                    <div className="card">
                                        <div className="card-over d-flex justify-content-center align-items-center ">
                                            <div className="text-center">
                                                <h4>Více</h4>
                                                <i className="fas fa-search"></i>
                                            </div>
                                        </div>
                                        <div className="row" style={{ height: "50%" }}>
                                            <div className="card-body d-flex flex-column">
                                                <div className="row" style={{ height: "90%" }}>
                                                    <div className="align-self-stretch"><h5 className="card-title">{title}</h5></div>
                                                    <div className="align-self-stretch"><p className="card-text">{post.frontmatter.description}</p></div>
                                                </div>
                                                <div className="row">
                                                    <div className="align-self-stretch"><p className="card-date"><small className="text-muted">{date}</small></p></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <img alt="" src={image} className="card-image" />
                                        </div>
                                    </div>
                                </a>
                            )
                        })}
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default News