import React from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { CopyToClipboard } from "react-copy-to-clipboard"

import Map from "../Map"
import "./Residence.scss"
import logoDotace from "../../images/logo/loga-dotace.png"

const Residence = () => {
    const intl = useIntl();
    return (
        <section id="residence" className="page-section">
            <div className="container">
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                <FormattedMessage id="residence.title" />
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row contact-info">
                    <div className="col-lg-4 col-12">
                        <div className="row">
                            <div className="col-12 col-md-4 col-lg-12">
                                <div className="d-inline-flex align-items-center">
                                    <h5>
                                        <FormattedMessage id="residence.billingInfo" />
                                    </h5>
                                    <CopyToClipboard text={`coalios s.r.o.\nKoliště 1912/13, Brno-střed, 602 00 Brno\nIC: 11998831\nDIC: CZ11998831`}>
                                        <span title={intl.formatMessage({ id: "copytext" })} className="ml-2 copy-icon"><i className="far fa-copy"></i></span>
                                    </CopyToClipboard>
                                </div>
                                <p className="text-muted">
                                    coalios s.r.o. <br />
                                    Koliště 1912/13, Brno-střed, 602 00 Brno <br />
                                    IC: 11998831<br />
                                    DIC: CZ11998831
                                </p>
                            </div>
                            <div className="col-12 col-md-4 col-lg-12">
                                <h5>
                                    <FormattedMessage id="residence.addressInfo" />
                                </h5>
                                <div className="d-inline-flex align-items-center">
                                    <p className="mb-0 font-weight-bold text-muted"><FormattedMessage id="residence.office" /> Brno</p>
                                    <CopyToClipboard text="Koliště 1912/13, 602 00 Brno-střed-Černá Pole">
                                        <span title={intl.formatMessage({ id: "copytext" })} className="ml-2 copy-icon"><i className="far fa-copy"></i></span>
                                    </CopyToClipboard>
                                </div>
                                <p className="text-muted">Koliště 1912/13, 602 00 Brno-střed-Černá Pole</p>
                                <div className="d-inline-flex align-items-center">
                                    <p className="mb-0 font-weight-bold text-muted"><FormattedMessage id="residence.office" /> Letohrad</p>
                                    <CopyToClipboard text="U Stadionu 923, 561 51 Letohrad">
                                        <span title={intl.formatMessage({ id: "copytext" })} className="ml-2 copy-icon"><i className="far fa-copy"></i></span>
                                    </CopyToClipboard>
                                </div>
                                <p className="text-muted">U Stadionu 923, 561 51 Letohrad</p>
                                <p className="mb-0 font-weight-bold text-muted"><FormattedMessage id="residence.office" /> Praha <small>(Success Solutions)</small></p>
                                <p className="text-muted">Kafkova 605/16, 160 00 Praha 6-Dejvice</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12">
                        <Map></Map>
                    </div>
                </div>
                <div className="dotace-logo">
                    <a
                        href="/ministry"
                        rel="noopener noreferrer">
                        <img src={logoDotace} className="dotace-img" alt="loga-dotace" />
                    </a>
                </div>
            </div>
        </section>
    )
}

export default Residence
