import React from "react"
import './contactButton.scss'

const Button = (props) => {
    
    const navToContact = () => {
        const targetSection = document.getElementById("contacts");
        window.scrollTo({
            top: targetSection.offsetTop - 70,
            behavior: 'smooth'
          });
    };

    return (
        <button className="section-button" onClick={ navToContact } type="button">{ props.btnText }</button>
    )
    
};

export default Button;