import React, { useState } from "react"
import { FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl"
import "./Implementation.scss"
import Button from "../ContactButton/contactButton"

const Implementation = () => {
    const [formData, setFormData] = useState({
        checkedBtn: "contact"
    });
    const onStepPress = (id) => {
        setFormData({checkedBtn: id});
    }

    return (
        <section id="implementation" className="page-section">
            <div className="container">
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                <FormattedMessage id="implementation.title" />
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center title-description">
                    <div className="col-12  col-lg-8 text-center">
                        <p className="title-description">
                            <FormattedHTMLMessage className="text-regular" id="implementation.text" />
                        </p>
                    </div>
                </div>
                <div className="implementation-container">
                    <input type="radio" className="contact" checked={formData.checkedBtn === "contact"} id="contact"></input>
                    <input type="radio" className="donation" checked={formData.checkedBtn === "donation"} id="donation"></input>
                    <input type="radio" className="implementation" checked={formData.checkedBtn === "implementation"} id="implementation"></input>
                    <input type="radio" className="support" checked={formData.checkedBtn === "support"} id="support"></input>
                    <div className="head">
                        <ul className="nav">
                        { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */ }
                            <li className="st st1 active" onClick={() => onStepPress("contact")}>
                                <h3 className="inner"><FormattedMessage id="implementation.steps.first.point" /></h3>
                            </li>
                        { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */ }
                            <li className="st st2" onClick={() => onStepPress("donation")}>
                                <h3 className="inner"><FormattedMessage id="implementation.steps.second.point" /></h3>
                            </li>
                        { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */ }
                            <li className="st st3" onClick={() => onStepPress("implementation")}>
                                <h3 className="inner"><FormattedMessage id="implementation.steps.third.point" /></h3>
                            </li>
                        { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */ }
                            <li className="st st4" onClick={() => onStepPress("support")}>
                                <h3 className="inner"><FormattedMessage id="implementation.steps.fourth.point" /></h3>
                            </li>
                        </ul>
                        <div className="line">
                            <span></span>
                        </div>
                    </div>
                    <div className="content">
                        <section className="contact-section">
                            <span><i className="fas fa-comments fa-4x"></i></span>
                            <h3 className="bold-title"><FormattedMessage id="implementation.steps.first.title" /></h3>
                            <p className="basic-text"><FormattedHTMLMessage id="implementation.steps.first.text" /></p>
                        </section>
                        <section className="donation-section">
                            <span><i className="fas fa-money-check-alt fa-4x"></i></span>
                            <h3 className="bold-title"><FormattedMessage id="implementation.steps.second.title" /></h3>
                            <p className="basic-text"><FormattedHTMLMessage id="implementation.steps.second.text" /></p>
                        </section>
                        <section className="implementation-section">
                            <span><i className="fas fa-wrench fa-4x"></i></span>
                            <h3 className="bold-title"><FormattedMessage id="implementation.steps.third.title" /></h3>
                            <p className="basic-text"><FormattedHTMLMessage id="implementation.steps.third.text" /></p>
                        </section>
                        <section className="support-section">
                            <span><i className="fas fa-rocket fa-4x"></i></span>
                            <h3 className="bold-title"><FormattedMessage id="implementation.steps.fourth.title" /></h3>
                            <p className="basic-text"><FormattedHTMLMessage id="implementation.steps.fourth.text" /></p>
                        </section>
                    </div>
                </div>
            </div>
                <Button btnText={ "Chci poradit" }/>
        </section>
    )
}

export default Implementation