import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl"
import noImage from "../../images/no-image.png"
import Slider from "react-slick"
import "./Sponsorships.scss"

const Sponsorships = () => {
    const sponsorshipsQuery = useStaticQuery(graphql`
    {
        allMarkdownRemark(sort: {fields: [frontmatter___index], order: ASC}) {
            nodes {
              excerpt
              fields {
                slug
              }
              frontmatter {
                index
                title
                description
                thumbnail
                pagelink
              }
            }
          }
      }
      `);

    const sponsorships = sponsorshipsQuery.allMarkdownRemark.nodes.filter(entity => entity.fields.slug?.includes("/sponsorship-") ? entity : null);

    const isInfinite = sponsorships.length > 4 ? true : false;
    const settings = {
        dots: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: isInfinite,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
        ],
    }

    return (
        <section id="partner" className="page-section">
            <div className="container">
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                <FormattedMessage id="sponsorships.title" />
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center title-description">
                    <div className="col-12  col-lg-8">
                        <FormattedHTMLMessage className="title" id="sponsorships.subtitle" />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <Slider className="coal-slick" {...settings}>
                        {sponsorships.map(reference => {
                            const title = reference.frontmatter.title;
                            const description = reference.frontmatter.description;
                            const pagelink = reference.frontmatter.pagelink;
                            var image = reference.frontmatter.thumbnail || noImage;
                            var splitPath = image.split("static/");
                            if (splitPath.length > 1) {
                                image = splitPath[0] + splitPath[1]
                            }
                            return (
                                <div className="partner-box">
                                    <div className="img-container">
                                        <div className="imgBox">
                                            <a rel="noreferrer" href={pagelink} target="_blank"><img className="ref-img" alt={reference.frontmatter.alt} loading="lazy" src={image}></img></a>

                                        </div>
                                    </div>
                                    <div className="partner-header">
                                        <h4 className="partner-title">{title}</h4>
                                    </div>
                                    <div className="partner-text">
                                        <p>
                                            {description}
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default Sponsorships