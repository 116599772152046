import React from "react"
import { FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"
import "./Jobs.scss"

const Jobs = () => {
    const posts = useStaticQuery(graphql`
    query {
        allMarkdownRemark(sort: { fields: [frontmatter___priority], order: ASC }) {
            nodes {
                excerpt
                fields {
                    slug
                }
                frontmatter {
                    priority
                    title
                    icon
                    description
                }
            }
        }
    }`);
    const jobs = posts.allMarkdownRemark.nodes.filter(entity => entity.fields.slug?.includes("/job-post-") ? entity : null);

    return (
        <section id="jobs" className="page-section background-gray">
            <div className="container">
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                <FormattedMessage id="jobs.title" />
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center title-description">
                    <div className="col-12  col-lg-8">
                        <FormattedHTMLMessage className="text-regular" id="jobs.text" />
                    </div>
                </div>
                <div className="row justify-content-center">
                    {jobs.map((post, index) => {
                        const title = post.frontmatter.title;
                        const description = post.frontmatter.description;
                        const icon = post.frontmatter.icon;
                        return (
                            <div className="card">
                                <div className="card-body">
                                    <div className="main-body">
                                        <div className="card-row">
                                            <h5 className="card-title">{title}</h5>
                                            {icon === "junior" ? <i className="fas fa-user-graduate fa-2x card-title-image"></i> : <i className="fas fa-user-tie fa-2x card-title-image"></i>}
                                        </div>
                                        <p className="card-text">{description}</p>
                                    </div>
                                    <div className="more-link">
                                        { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                        <a key={index} href={post.fields.slug} className="card-link"><FormattedMessage id="inuvio.more" /></a>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default Jobs